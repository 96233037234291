
import GeneralData from "@/modules/purchaseOrders/components/GeneralData.vue";
import Items from "@/modules/purchaseOrders/components/Items.vue";
import usePurchaseOrder from "@/modules/purchaseOrders/composables/usePurchaseOrder";
import toastr from "toastr";
import {onMounted, Ref, ref} from "vue";
import router from "@/router";
import Loading from "@/components/Loading.vue";
import {PurchaseOrderCatalogs} from "@/modules/purchaseOrders/interfaces";

export default {
    components: {Loading, GeneralData, Items},
    setup() {
        const {clearForm, purchaseOrderForm, createPurchaseOrder, getCatalogs} = usePurchaseOrder()
        const sending = ref(false)
        const loading = ref(true)
        const catalogs: Ref<PurchaseOrderCatalogs | null> = ref(null)

        clearForm();
        onMounted(async () => {
            catalogs.value = await getCatalogs()
            loading.value = false
        });

        return {
            mode: 'CREATE',
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'purchase_orders'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createPurchaseOrder(purchaseOrderForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'purchase_orders'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
